.logoContainer {
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    align-items: center; /* Center the logo vertically */
    height: 100px; /* Adjust height as needed */
    overflow: hidden; /* Prevent overflow during animation */
}

.logoText {
    color: #20221B; /* Apple green color */
    font-size: 3rem; /* Make the logo text bigger */
    animation: move 3s linear infinite; /* Animation for moving */
    transition: color 0.5s ease; /* Smooth transition for color change */
}

/* Keyframes for the animation */
@keyframes move {
    0% {
        transform: translateX(-500px); /* Start position */
    }
    50% {
        transform: translateX(10px); /* Move to the right */
    }
    100% {
        transform: translateX(500px); /* Return to start position */
    }
}

/* Style for hover and active states */
.logoText:hover,
.logoText:active {
    color: #CFEE9E; /* Bright pink color */
}