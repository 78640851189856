body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #20221B; /* Eerie Black */
}

.App {
  text-align: center;
  background: linear-gradient(to bottom, #20221B, #F6FCEE); /* Eerie Black to Ivory */
  min-height: 100vh; /* Ensure it covers the full height */
  padding-top: 5px; /* Adjust based on navbar height */
  position: relative; /* Added for z-index context */
  z-index: 1; /* Ensure it is below the navbar */
}

/* Example CSS for the fluffy cloud-shaped navbar */
.navbar {
  background-color: #F6FCEE; /* Ivory */
  position: absolute; /* Change to absolute to overlap with the gradient */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  z-index: 10; /* Ensure it is above the gradient */
  padding: 9px; /* Reduced padding for a smaller navbar */
  border-radius: 1000px; /* Smaller rounded edges */
  box-shadow: 0 2px 100px #394032; /* Black Olive shadow */
  display: flex; /* Flexbox for alignment */
  justify-content: center; /* Center the content */
  align-items: center; /* Center vertically */
}

/* Optional: Style for nav links */
.nav-link {
  color: #F5ABBB; /* Cherry Blossom Pink */
  text-decoration: none;
  margin: 0 15px; /* Spacing between links */
  font-weight: bold; /* Make links bold */
}

:root {
  --logo-size: 10rem;
}

.logo {
  color: #20221B; /* Eerie Black */
  font-size: 3rem;
  font-weight: bold;
  transform: scale(2);
  transform-origin: left center;
  margin: 0;
  padding: 10px 0;
  animation: logoMove 2s ease-in-out infinite;
}

@keyframes logoMove {
  0% {
    transform: translateX(-500px);
  }
  50% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(500px);
  }
}

.logo:hover {
  animation-play-state: paused;
}

.logo-letter {
  display: inline-block;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.logo-letter.active {
  color: #F5ABBB; /* Cherry Blossom Pink when active */
  text-shadow: 0 0 10px #F5ABBB; /* Add glow effect when active */
}

.sign-in-button {
  background-color: #F6FCEE; /* Ivory */
  color: #20221B; /* Eerie Black */
  padding: 7px 17px;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.featured-brand {
  position: relative;
  width: 100%;
  height: auto; /* Remove fixed height */
  margin-bottom: 0; /* Remove margin */
}

.clip-placeholder {
  position: relative;
  width: 100%;
  height: 100vh; /* Make it full viewport height */
  overflow: hidden;
}

.clip-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* Create a gradient overlay for fade effect */
.clip-placeholder::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, 
    rgba(32, 33, 27, 0) 60%, 
    rgba(32, 33, 27, 1) 100%
  );
  pointer-events: none;
}


.brand-of-the-day {
  position: absolute;
  bottom: 40vh;
  left: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 3;
}

.brand-of-the-day h2 {
  margin: 0;
  font-size: 4rem;  /* Increased from 2.7rem to 4rem */
  color: #F6FCEE; /* Ivory */
  text-shadow: 2px 2px 4px #394032; /* Black Olive shadow */
}

.interactive-letter {
  display: inline-block;
  transition: transform 0.2s ease;  /* Shortened transition time for quicker response */
}

/* Add a hover effect for additional interactivity */
.interactive-letter:hover {
  color: #F5ABBB;  /* Cherry Blossom Pink on hover */
  text-shadow: 0 0 10px #F5ABBB;  /* Add glow effect on hover */
}

@keyframes wavyFloat {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px) rotate(5deg);
  }
}

.brand-of-the-day h2 {
  display: inline-block;
}

.brand-of-the-day h2 span {
  display: inline-block;
  transition: transform 0.3s ease;
}

.brand-of-the-day h2 span:hover {
  transform: translateY(-5px) rotate(10deg);
}

.brand-of-the-day h2 {
  margin: 0;
  font-size: 2.7rem;
  color: #F6FCEE; /* Ivory */
}

.brand-of-the-day h2 span {
  display: inline-block;
  animation: wavyFloat 2s ease-in-out infinite;
}

@keyframes flicker {
  0%, 100% { opacity: 1; }
  41% { opacity: 1; }
  42% { opacity: 0.8; }
  43% { opacity: 1; }
  45% { opacity: 0.3; }
  46% { opacity: 1; }
}

.flickering-text {
  animation: flicker 1.5s infinite alternate;
}

.brand-of-the-day {
  position: absolute;
  bottom: 40vh; /* Adjust as needed */
  left: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 3;
}

.brand-of-the-day h2 {
  margin: 0;
  font-size: 2.7rem;
  color: #F6FCEE; /* Ivory */
  text-shadow: 2px 2px 4px #394032; /* Black Olive shadow */
  animation: flicker 1.5s infinite alternate;
}

.brand-sections {
  position: relative;
  z-index: 2;
  padding: 20px;
  margin-top: -30vh; /* Adjust this value to control where brands appear over the video */
}

.brand-section {
  margin-bottom: 40px;
}

.city-title {
  text-align: left;
  margin-left: 20px;
  color: #F6FCEE; /* Ivory */
  text-shadow: 2px 2px 4px #394032; /* Black Olive shadow */
}

/* ... keep your existing styles ... */

.brand-grid {
  display: flex;
  overflow-x: auto;
  padding: 20px;
  gap: 30px;
  position: relative; /* Added for z-index context */
}

.brand-placeholder {
  flex: 0 0 auto;
  width: 300px;
  height: 150px;
  border: none; /* Removed border from here */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0s 0.3s;
  color: #F6FCEE; /* Ivory */
  overflow: visible;
  position: relative;
  z-index: 1;
}

.brand-placeholder::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(32, 33, 27, 0.7); /* Eerie Black with transparency */
  border-radius: 5px;
  z-index: -1;
  transition: transform 0.3s ease;
}

.brand-placeholder::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid white;
  border-radius: 5px;
  transition: transform 0.3s ease;
  pointer-events: none; /* Allows interaction with elements behind */
}

.brand-placeholder:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  z-index: 10;
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0s;
}

.brand-placeholder:hover::before {
  transform: scale(1.1); /* Background zooms in slightly */
}

.brand-placeholder:hover::after {
  transform: scale(1); /* Outline zooms out */
}

.brand-placeholder span {
  opacity: 0.7;
  transition: transform 0.3s ease;
  position: relative;
  z-index: 2;
}

.brand-placeholder:hover span {
  transform: scale(1.1);
}
.brand-grid {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
}

.brand-placeholder {
  flex: 0 0 auto;
  width: 300px;
  height: 150px;
  overflow: hidden;
  border-radius: 5px;
}

.brand-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.brand-placeholder:hover .brand-image {
  transform: scale(1.1);
}

.news-section {
  padding: 40px 20px;
  position: relative;
  z-index: 2;
}

.section-title {
  text-align: left;
  color: #F6FCEE; /* Ivory */
  font-size: 2rem;
  margin-bottom: 30px;
  padding-left: 20px;
}

.news-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.news-item {
  width: 300px;
  background-color: #20221B; /* Eerie Black */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(32, 33, 27, 0.1); /* Eerie Black shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.news-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(32, 33, 27, 0.2); /* Eerie Black shadow */
}

.news-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.news-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.news-item:hover .news-image {
  transform: scale(1.05);
}

.news-content {
  padding: 20px;
}

.news-title {
  margin: 0;
  font-size: 1.2rem;
  color: #F6FCEE; /* Ivory */
  line-height: 1.4;
}

.world-map-link-container {
  text-align: center;
  padding: 40px 0;
}

.world-map-link {
  display: inline-block;
  padding: 12px 24px;
  background-color: #F6FCEE; /* Ivory */
  color: #20221B; /* Eerie Black */
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.world-map-link:hover {
  background-color: #F5ABBB; /* Cherry Blossom Pink */
  transform: translateY(-2px);
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  font-size: 24px;
  color: #F6FCEE; /* Ivory */
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #F5ABBB; /* Cherry Blossom Pink */
}

/* ... existing styles ... */

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}
