/* src/CookieConsent.css */
.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(207, 238, 158, 0.8); /* Made background slightly transparent */
    border: 1px solid #CFEE9E;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(32, 33, 27, 0.5); /* Adjusted shadow for transparency */
    z-index: 999; /* Lower z-index to ensure it's in front of images */
    width: 300px;
    text-align: center;
    border-radius: 50px; /* Changed to make the cookie consent circle */
    color: #20221B; /* Changed font color to a darker shade */
    border-color: #20221B; /* Added border color */
}

.cookie-consent button {
    margin: 5px;
    padding: 10px 15px;
    border: none;
    background-color: rgba(207, 238, 158, 0.8); /* Made background slightly transparent */
    color: #20221B; /* Changed font color to white */
    cursor: pointer;
    border-radius: 50px; /* Changed to make the buttons circle */
    border-color: #20221B; /* Added border color */
}

.cookie-consent button:hover {
    background-color: rgba(245, 171, 187, 0.8); /* Made background slightly transparent */
}