/* The problem in this file is that it is not properly organized and lacks comments and structure. It is difficult to understand and maintain. */

/* Let's start by organizing the file into sections and adding comments for each section. */

/* Section 1: Rain and Drop Styles */
.rain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allow clicks to pass through */
    overflow: hidden; /* Hide overflow */
    z-index: 0; /* Behind other content */
}

.drop {
    position: absolute;
    background: #20221B; /* Eerie Black color */
    width: 2px; /* Width of the raindrop */
    height: 10px; /* Height of the raindrop */
    animation: fall linear infinite; /* Animation for falling */
}

@keyframes fall {
    0% {
        transform: translateY(-100px); /* Start above the viewport */
    }
    100% {
        transform: translateY(100vh); /* Fall to the bottom */
    }
}

/* Section 2: Container Styles */
.answers-container {
    margin-top: 10px; /* Adjust this value to move the answers down */
    position: relative; /* Position relative for absolute children */
    z-index: 1; /* Ensure it is above the rain */
}

.image-container {
    position: relative; /* Position relative for absolute children */
    display: flex;
    justify-content: center; /* Center images horizontally */
    align-items: center; /* Center images vertically */
    margin: 10px; /* Space between images */
    overflow: hidden; /* Ensure images fit within the container */
    width: 150px; /* Increased width for the container */
    height: 150px; /* Increased height for the container */
    animation: float 3s ease-in-out infinite; /* Add animation */
    box-shadow: 0 0 15px #F5ABBB; /* New box shadow */
    border: 3px solid #CFEE9E; /* Consistent green outline */
}

.image-container img {
    width: 100%; /* Make the image fill the container */
    height: 100%; /* Make the image fill the container */
    object-fit: cover; /* Ensure the image covers the container */
}

.image-container button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.image-container button:hover {
    background: rgba(32, 34, 27, 0.1);
}

/* Section 3: Image Grid Styles */
.image-grid {
    display: grid; /* Use grid layout */
    grid-template-columns: 1fr; /* Single column layout */
    gap: 10px; /* Space between grid items */
    justify-items: center; /* Center items in each grid cell */
    margin: 20px auto; /* Center the grid on the page */
    max-width: 800px; /* Set a max width for the grid */
}

/* Section 4: Floating Animation Keyframes */
@keyframes float {
    0% {
        transform: translateY(0); /* Start position */
    }
    50% {
        transform: translateY(-10px); /* Move up */
    }
    100% {
        transform: translateY(0); /* Return to start position */
    }
}

/* Section 5: Heart Shape Styles */
.heart {
    clip-path: polygon(50% 0%, 75% 20%, 100% 50%, 75% 80%, 50% 100%, 25% 80%, 0% 50%, 25% 20%);
    background: transparent; /* No background color */
    border: 3px solid #00ff00; /* Consistent green outline */
    width: 200px; /* Adjust width */
    height: 200px; /* Adjust height */
    display: flex; /* Center content */
    justify-content: center; /* Center images horizontally */
    align-items: center; /* Center images vertically */
    overflow: hidden; /* Ensure images fit within the container */
}

/* Section 6: Circle Shape Styles */
.circle {
    border-radius: 50%; /* Make the container circular */
}

/* Section 7: Square Shape Styles */
.square {
    background: transparent; /* No background color */
}

/* Section 8: Star Shape Styles */
.star {
    clip-path: polygon(
        50% 0%, 
        61% 35%, 
        98% 35%, 
        68% 57%, 
        79% 91%, 
        50% 70%, 
        21% 91%, 
        32% 57%, 
        2% 35%, 
        39% 35%
    );
    background: transparent; /* No background color */
    border: 3px solid #00ff00; /* Consistent green outline */
}

/* Section 9: Triangle Shape Styles */
.triangle {
    clip-path: polygon(
        0% 0%, 
        100% 0%, 
        50% 100%
    );
    background: transparent; /* No background color */
}

/* Section 10: Eight Corners Shape Styles */
.eight-corners {
    clip-path: polygon(
        0% 0%, 
        100% 0%, 
        100% 100%, 
        0% 100%, 
        0% 50%, 
        100% 50%, 
        50% 50%, 
        50% 100%, 
        50% 25%, 
        50% 75%
    );
    background: transparent; /* No background color */
}

/* Section 11: Rectangle Shape Styles */
.rectangle {
    background: transparent; /* No background color */
}

/* Section 12: Diamond Shape Styles */
.diamond {
    clip-path: polygon(
        50% 0%, 
        100% 50%, 
        50% 100%, 
        0% 50%
    );
    background: transparent; /* No background color */
    border: 3px solid #00ff00; /* Consistent green outline */
    width: 150px; /* Adjust width */
    height: 150px; /* Adjust height */
    display: flex; /* Center content */
    justify-content: center; /* Center images horizontally */
    align-items: center; /* Center images vertically */
    overflow: hidden; /* Ensure images fit within the container */
}

/* Section 13: Text Container Styles */
.text-container {
    margin-top: 50px; /* Default spacing for desktop */
}

/* Section 14: Roast Container Styles */
.roast-container {
    margin-top: 150px; /* Increase this value to move the text lower */
    padding: 20px; /* Optional: Add padding for better spacing */
    background: rgba(57, 64, 50, 0.795); /* Optional: Background for readability, set to Black Olive */
    border-radius: 10px; /* Optional: Rounded corners */
    box-shadow: 0 10px 10px #cfee9e; /* Optional: Shadow for depth, set to CFEE9E */
    z-index: 1000; /* Added z-index for layering */
    color: #f6fcee; /* Set text color to white */
}

/* Section 15: Question Text Styles */
.question-text {
    text-align: center;
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 0.5cm; /* Set margin to 0.5cm for spacing */
    text-transform: uppercase;
    font-weight: bold;
    animation: fadeIn 0.5s ease, glow 2s ease-in-out infinite;
    padding: 0 2rem;
    max-width: 1200px;
    margin: 0 auto; /* Center the question text */
}

/* Section 16: Answers Label Styles */
.answers-label {
    font-size: 1.8rem; /* Font size for the label */
    color: #ffffff; /* Change this to your desired text color */
    text-align: center; /* Center the text */
    margin-top: 100px; /* Space above the label */
    margin-bottom: 1px; /* Space below the label */
    animation: colorChange 1s infinite; /* Animation for color change */
    font-weight: bold; /* Make the text bold */
    font-family: 'Braggadocio', sans-serif; /* Change the font to Braggadocio */
}

/* Section 17: Color Change Animation Keyframes */
@keyframes colorChange {
    0% {
        text-shadow: 2px 2px 4px #20221B, /* Eerie Black */
                      0 0 25px #394032, /* Black Olive */
                      0 0 5px #cfee9e; /* Mindaro */
    }
    25% {
        text-shadow: 2px 2px 4px #F6FCEE, /* Ivory */
                      0 0 25px #cfee9e, /* Cherry Blossom Pink */
                      0 0 5px #20221B; /* Eerie Black */
    }
    50% {
        text-shadow: 2px 2px 4px #394032, /* Black Olive */
                      0 0 25px #cfee9e, /* Mindaro */
                      0 0 5px #F6FCEE; /* Ivory */
    }
    75% {
        text-shadow: 2px 2px 4px #F5ABBB, /* Cherry Blossom Pink */
                      0 0 25px #20221B, /* Eerie Black */
                      0 0 5px #394032; /* Black Olive */
    }
    100% {
        text-shadow: 2px 2px 4px #cfee9e, /* Mindaro */
                      0 0 25px #F6FCEE, /* Ivory */
                      0 0 5px #F5ABBB; /* Cherry Blossom Pink */
    }
}

/* Section 18: Animated Background Styles */
.animated-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; /* In front of all other content */
    opacity: 0.5; /* Adjust opacity as needed */
    pointer-events: none; /* Prevent interaction with the background */
}

/* Section 19: Fly Animation Keyframes */
@keyframes fly {
    0% { transform: translateX(-500%); }
    100% { transform: translateX(500%); }
}

/* Section 20: Flying Cow Styles */
.flying-cow {
    animation: fly 7s linear infinite; /* Reduced duration for faster animation */
    background-image: url('./background/cow.jpeg'); /* Ensure this path is correct */
    background-size: contain; /* Adjust size */
    background-repeat: no-repeat;
    width: 1000px; /* Make the cow very big */
    height: 500px; /* Make the cow very big */
    position: absolute;
    top: 20%; /* Adjust position to be higher on the screen */
    left: 50%; /* Start in the middle of the screen */
    transform: translate(-50%, -50%); /* Center the cow */
    animation-direction: alternate; /* Move from different corners every time it appears */
    z-index: 1; /* Ensure the cow is in front of the rainbow */
}

/* Section 21: Rainbow Styles */
.rainbow {
    animation: 3s linear infinite; /* Adjust duration as needed */
    background-image: url('./background/rainbow.jpeg'); /* Ensure this path is correct */
    background-size: cover; /* Adjust size */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: absolute;
    top: 50%; /* Center the rainbow */
    left: 50%; /* Center the rainbow */
    transform: translate(-50%, -50%); /* Center the rainbow */
    z-index: 1; /* Ensure the rainbow is behind the cow */
    animation-name: rainbowAnimation; /* Define the animation name */
}

/* Section 22: Rainbow Animation Keyframes */
@keyframes rainbowAnimation {
    0% { background-position: 0% 50%; opacity: 0; }
    25% { background-position: 25% 50%; opacity: 1; }
    50% { background-position: 50% 50%; opacity: 1; }
    75% { background-position: 75% 50%; opacity: 1; }
    100% { background-position: 100% 50%; opacity: 0; }
}

/* Section 23: Eye Animation Keyframes */
@keyframes eyeAnimation {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.parent-container {
    position: relative; /* Set positioning context */
    width: 100%; /* Full width */
    height: 100vh; /* Full height of the viewport */
}

.bathtub {
    background-image: url('./background/bathtub.jpeg'); /* Path to the bathtub image */
    background-size: cover; /* Cover the entire div */
    width: 300px; /* Adjust width as needed */
    height: 150px; /* Adjust height as needed */
    position: absolute; /* Position it absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the bathtub */
    animation: bathtubAnimation 5s ease-in-out infinite; /* Animation duration and type */
    z-index: 1; /* Ensure it's in front of other content */
}
@keyframes bathtubAnimation {
    0% { transform: translate(-50%, -50%); }
    50% { transform: translate(-50%, -20%); }
    100% { transform: translate(-50%, -50%); }
}

/* Section: Flower Animation Keyframes */
@keyframes flowerAnimation {
    0% { opacity: 0; transform: translateY(20px); } /* Start invisible and slightly below */
    50% { opacity: 1; transform: translateY(-20px); } /* Fully visible and slightly above */
    100% { opacity: 0; transform: translateY(20px); } /* Fade out and move down */
}

/* Flower Styles */
.flowers {
    background-image: url('./background/flowers.jpeg'); /* Path to the flowers image */
    background-size: cover; /* Cover the entire div */
    width: 100%; /* Adjust width to cover the viewport */
    height: 100vh; /* Adjust height to cover the viewport */
    position: absolute; /* Position it absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the flowers */
    animation: flowerAnimation 5s ease-in-out infinite; /* Animation duration and type */
    z-index: 1; /* Ensure it's in front of other content */
}

.parent-container {
    position: relative; /* Set positioning context */
    width: 100%; /* Adjust as needed */
    height: 100vh; /* Adjust as needed */
}

/* Section 24: Eye Styles */
.eye {
    animation: eyeAnimation 3s linear infinite;
    background-image: url('./background/eye.jpeg');
    background-size: cover;
    width: 100%; /* Make the eye cover the entire container */
    height: 100%; /* Make the eye cover the entire container */
    position: absolute;
    top: 50%; /* Center the eye vertically */
    left: 50%; /* Center the eye horizontally */
    transform: translate(-50%, -50%); /* Center the eye */
    z-index: 1; /* Ensure the eye is in front of other content */
    display: block; /* Ensure the eye is visible */
}

/* Section 19: Fly Animation Keyframes */
@keyframes fly {
    0% { transform: translateX(-500%); }
    100% { transform: translateX(500%); }
}

/* Section 20: Flying Dog Styles */
.flying-dog {
    animation: fly 7s linear infinite; /* Reduced duration for faster animation */
    background-image: url('./background/dog.jpeg'); /* Ensure this path is correct */
    background-size: contain; /* Adjust size */
    background-repeat: no-repeat;
    width: 1000px; /* Make the cow very big */
    height: 500px; /* Make the cow very big */
    position: absolute;
    top: 20%; /* Adjust position to be higher on the screen */
    left: 50%; /* Start in the middle of the screen */
    transform: translate(-50%, -50%); /* Center the cow */
    animation-direction: alternate; /* Move from different corners every time it appears */
    z-index: 1000; /* Ensure the cow is in front of the rainbow */
}

/* Section: Circular Movement Keyframes */
@keyframes circularMovement {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Box Styles */
.moving-box {
    position: absolute; /* Position it absolutely */
    top: 30%; /* Center vertically */
    left: 30%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the box */
    width: 40%; /* Adjust width for mobile */
    height: 35%; /* Adjust height for mobile */
    border: 20px solid; /* Adjust border thickness for mobile */
    border-image: linear-gradient(135deg, #20221B, #394032, #cfee9e) 1; /* Gradient border with Eerie Black, Black Olive, and Mindaro */
    z-index: 1; /* Ensure it's in front of other content */
    animation: circularMovement 7s linear infinite; /* Circular movement animation */
}

/* Additional Moving Box Styles */
.additional-moving-box {
    position: absolute; /* Position it absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the box */
    width: 40%; /* Adjust width for mobile */
    height: 35%; /* Adjust height for mobile */
    border: 20px solid; /* Adjust border thickness for mobile */
    border-image: linear-gradient(135deg, #20221B, #394032, #cfee9e) 1; /* Gradient border with Eerie Black, Black Olive, and Mindaro */
    z-index: 1; /* Ensure it's in front of other content */
    animation: circularMovement 7s linear infinite; /* Circular movement animation */
}

.additional-moving-box.round {
    border-radius: 50%; /* Ensure it is round */
    border-image: linear-gradient(135deg, #20221B, #394032, #cfee9e) 1; /* Gradient border with Eerie Black, Black Olive, and Mindaro */
    position: absolute; /* Position it absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the box */
    z-index: 1; /* Ensure it's in front of other content */
    animation: circularMovement 7s linear infinite; /* Circular movement animation */
    border-radius: 50%; /* Double-check to ensure it is round */
}
/* Section 25: Bouncing Balls Styles */
.bouncing-ball {
    position: absolute; /* Position absolutely within the parent container */
    width: 30px; /* Width of the ball */
    height: 30px; /* Height of the ball */
    border-radius: 50%; /* Make it circular */
    animation: bounce 2s ease-in-out infinite; /* Animation for bouncing */
    z-index: 1; /* Ensure the balls are in front of other content */
    bottom: 0; /* Start from the bottom of the page */
}

/* Define different colors for the balls */
.ball-color-1 { background-color: #20221B; }
.ball-color-2 { background-color: #F5ABBB; }
.ball-color-3 { background-color: #cfee9e; }
.ball-color-4 { background-color: #F6FCEE; }
.ball-color-5 { background-color: #F5ABBB; }

/* Keyframes for bouncing animation */
@keyframes bounce {
    0%, 100% { transform: translateY(0); } /* Start and end at original position */
    50% { transform: translateY(-700px); } /* Move up by 100px */
}

/* Positioning the balls at random heights */
.ball-1 { left: 10%; animation-delay: 0s; }
.ball-2 { left: 20%; animation-delay: 0.3s; }
.ball-3 { left: 30%; animation-delay: 0.8s; }
.ball-4 { left: 40%; animation-delay: 1s; }
.ball-5 { left: 50%; animation-delay: 0.4s; }
.ball-6 { left: 60%; animation-delay: 0.5s; }
.ball-7 { left: 70%; animation-delay: 0.6s; }
.ball-8 { left: 80%; animation-delay: 1.7s; }
.ball-9 { left: 90%; animation-delay: 0.8s; }
.ball-10 { left: 10%; animation-delay: 1.9s; }
.ball-11 { left: 20%; animation-delay: 1s; }
.ball-12 { left: 30%; animation-delay: 0.1s; }
.ball-13 { left: 40%; animation-delay: 1.1s; }
.ball-14 { left: 50%; animation-delay: 1.3s; }
.ball-15 { left: 60%; animation-delay: 1.4s; }
.ball-16 { left: 70%; animation-delay: 0.5s; }
.ball-17 { left: 80%; animation-delay: 1.6s; }
.ball-18 { left: 90%; animation-delay: 0.7s; }
.ball-19 { left: 10%; animation-delay: 1.8s; }
.ball-20 { left: 20%; animation-delay: 0.9s; }

/* Section: Twinkling Stars Styles */
.star {
    position: absolute; /* Position absolutely within the parent container */
    width: 60px; /* Adjust size as needed */
    height: 60px; /* Adjust size as needed */
    background-color: #cfee9e; /* Color of the star */
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%); /* Star shape */
    animation: twinkle 1.5s infinite; /* Animation duration and type */
    z-index: 1; /* Ensure the stars are in front of other content */
}

/* Keyframes for twinkling animation */
@keyframes twinkle {
    0%, 100% { opacity: 1; } /* Fully visible */
    50% { opacity: 0; } /* Invisible */
}

/* Positioning the stars at very random locations */
.star-1 { left: 14%; top: 27%; animation-delay: 0.1s; }
.star-2 { left: 81%; top: 43%; animation-delay: 0.3s; }
.star-3 { left: 59%; top: 19%; animation-delay: 0.5s; }
.star-4 { left: 31%; top: 67%; animation-delay: 0.7s; }
.star-5 { left: 48%; top: 85%; animation-delay: 0.9s; }
.star-6 { left: 22%; top: 51%; animation-delay: 1.1s; }
.star-7 { left: 75%; top: 39%; animation-delay: 1.3s; }
.star-8 { left: 56%; top: 11%; animation-delay: 1.5s; }
.star-9 { left: 98%; top: 73%; animation-delay: 1.7s; }
.star-10 { left: 41%; top: 62%; animation-delay: 1.9s; }
.star-11 { left: 13%; top: 58%; animation-delay: 2.1s; }
.star-12 { left: 69%; top: 25%; animation-delay: 2.3s; }
.star-13 { left: 85%; top: 49%; animation-delay: 2.5s; }
.star-14 { left: 26%; top: 82%; animation-delay: 2.7s; }
.star-15 { left: 53%; top: 36%; animation-delay: 2.9s; }
.star-16 { left: 61%; top: 54%; animation-delay: 3.1s; }
.star-17 { left: 44%; top: 68%; animation-delay: 3.3s; }
.star-18 { left: 17%; top: 45%; animation-delay: 3.5s; }
.star-19 { left: 79%; top: 29%; animation-delay: 3.7s; }
.star-20 { left: 55%; top: 31%; animation-delay: 1.8s; }
.star-21 { left: 62%; top: 55%; animation-delay: 1.8s; }
.star-22 { left: 43%; top: 70%; animation-delay: 1.8s; }
.star-23 { left: 40%; top: 21%; animation-delay: 1.8s; }
.star-24 { left: 71%; top: 49%; animation-delay: 1.8s; }
.star-25 { left: 52%; top: 65%; animation-delay: 1.8s; }
.star-26 { left: 14%; top: 27%; animation-delay: 0.1s; }
.star-27 { left: 81%; top: 43%; animation-delay: 0.3s; }
.star-28 { left: 59%; top: 19%; animation-delay: 0.5s; }
.star-29 { left: 31%; top: 67%; animation-delay: 0.7s; }
.star-30 { left: 48%; top: 85%; animation-delay: 0.9s; }
.star-31 { left: 22%; top: 51%; animation-delay: 1.1s; }
.star-32 { left: 75%; top: 39%; animation-delay: 1.3s; }
.star-33 { left: 56%; top: 11%; animation-delay: 1.5s; }
.star-34 { left: 98%; top: 73%; animation-delay: 1.7s; }
.star-35 { left: 41%; top: 62%; animation-delay: 1.9s; }
.star-36 { left: 13%; top: 58%; animation-delay: 2.1s; }
.star-37 { left: 69%; top: 25%; animation-delay: 2.3s; }
.star-38 { left: 85%; top: 49%; animation-delay: 2.5s; }
.star-39 { left: 26%; top: 82%; animation-delay: 2.7s; }
.star-40 { left: 53%; top: 36%; animation-delay: 2.9s; }
.star-41 { left: 61%; top: 54%; animation-delay: 3.1s; }
.star-42 { left: 44%; top: 68%; animation-delay: 3.3s; }
.star-43 { left: 17%; top: 45%; animation-delay: 3.5s; }
.star-44 { left: 79%; top: 29%; animation-delay: 3.7s; }
.star-45 { left: 55%; top: 31%; animation-delay: 1.8s; }
.star-46 { left: 62%; top: 55%; animation-delay: 1.8s; }
.star-47 { left: 43%; top: 70%; animation-delay: 1.8s; }
.star-48 { left: 40%; top: 21%; animation-delay: 1.8s; }
.star-49 { left: 71%; top: 49%; animation-delay: 1.8s; }
.star-50 { left: 52%; top: 65%; animation-delay: 1.8s; }
.star-51 { left: 14%; top: 27%; animation-delay: 0.1s; }
.star-52 { left: 81%; top: 43%; animation-delay: 0.3s; }
.star-53 { left: 59%; top: 19%; animation-delay: 0.5s; }
.star-54 { left: 31%; top: 67%; animation-delay: 0.7s; }
.star-55 { left: 48%; top: 85%; animation-delay: 0.9s; }
.star-56 { left: 22%; top: 51%; animation-delay: 1.1s; }
.star-57 { left: 75%; top: 39%; animation-delay: 1.3s; }
.star-58 { left: 56%; top: 11%; animation-delay: 1.5s; }
.star-59 { left: 98%; top: 73%; animation-delay: 1.7s; }
.star-60 { left: 41%; top: 62%; animation-delay: 1.9s; }
.star-61 { left: 13%; top: 58%; animation-delay: 2.1s; }
.star-62 { left: 69%; top: 25%; animation-delay: 2.3s; }
.star-63 { left: 85%; top: 49%; animation-delay: 2.5s; }
.star-64 { left: 26%; top: 82%; animation-delay: 2.7s; }
.star-65 { left: 53%; top: 36%; animation-delay: 2.9s; }
.star-66 { left: 61%; top: 54%; animation-delay: 3.1s; }
.star-67 { left: 44%; top: 68%; animation-delay: 3.3s; }
.star-68 { left: 17%; top: 45%; animation-delay: 3.5s; }
.star-69 { left: 79%; top: 29%; animation-delay: 3.7s; }
.star-70 { left: 55%; top: 31%; animation-delay: 1.8s; }
.star-71 { left: 62%; top: 55%; animation-delay: 1.8s; }
.star-72 { left: 43%; top: 70%; animation-delay: 1.8s; }
.star-73 { left: 40%; top: 21%; animation-delay: 1.8s; }
.star-74 { left: 71%; top: 49%; animation-delay: 1.8s; }
.star-75 { left: 52%; top: 65%; animation-delay: 1.8s; }
.star-76 { left: 14%; top: 27%; animation-delay: 0.1s; }
.star-77 { left: 81%; top: 43%; animation-delay: 0.3s; }
.star-78 { left: 59%; top: 19%; animation-delay: 0.5s; }
.star-79 { left: 31%; top: 67%; animation-delay: 0.7s; }
.star-80 { left: 48%; top: 85%; animation-delay: 0.9s; }
.star-81 { left: 22%; top: 51%; animation-delay: 1.1s; }
.star-82 { left: 75%; top: 39%; animation-delay: 1.3s; }
.star-83 { left: 56%; top: 11%; animation-delay: 1.5s; }
.star-84 { left: 98%; top: 73%; animation-delay: 1.7s; }
.star-85 { left: 41%; top: 62%; animation-delay: 1.9s; }
.star-86 { left: 13%; top: 58%; animation-delay: 2.1s; }
.star-87 { left: 69%; top: 25%; animation-delay: 2.3s; }
.star-88 { left: 85%; top: 49%; animation-delay: 2.5s; }
.star-89 { left: 26%; top: 82%; animation-delay: 2.7s; }
.star-90 { left: 53%; top: 36%; animation-delay: 2.9s; }
.star-91 { left: 61%; top: 54%; animation-delay: 3.1s; }
.star-92 { left: 44%; top: 68%; animation-delay: 3.3s; }
.star-93 { left: 17%; top: 45%; animation-delay: 3.5s; }
.star-94 { left: 79%; top: 29%; animation-delay: 3.7s; }
.star-95 { left: 55%; top: 31%; animation-delay: 1.8s; }
.star-96 { left: 62%; top: 55%; animation-delay: 1.8s; }
.star-97 { left: 43%; top: 70%; animation-delay: 1.8s; }
.star-98 { left: 40%; top: 21%; animation-delay: 1.8s; }
.star-99 { left: 71%; top: 49%; animation-delay: 1.8s; }
.star-100 { left: 52%; top: 65%; animation-delay: 1.8s; }

/* src/Quiz.css */
/* FrontPage.css */

/* Add styles for share buttons */
.share-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Space above the share icons */
}

.share-box {
    display: flex;
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center align items */
    justify-content: center; /* Center align items */
    width: 70px; /* Set a fixed width for the boxes */
    height: 70px; /* Set a fixed height for the boxes */
    padding: 10px; /* Add padding */
    margin: 0 10px; /* Space between boxes */
    background-color: rgba(57, 64, 50, 0.8); /* Slightly transparent background for the boxes */
    border: 1px solid #394830; /* Light border */
    border-radius: 5px; /* Rounded corners */
    text-decoration: none; /* Remove underline from links */
    color: #333; /* Text color */
    transition: background-color 0.3s; /* Smooth background color change */
}

.share-box:hover {
    background-color: #CFEE9E; /* Change background on hover */
}

.share-icon {
    width: 70px; /* Set the width of the icons */
    height: 70px; /* Set the height of the icons */
    margin-bottom: 5px; /* Space between icon and text */
    object-fit: contain; /* Ensure the image fits within the box */
}
.roast-title {
    font-size: 34px;
    font-weight: bold;
    color: #f5abbb; /* Change color as needed */
}

/* ImageStyles.css */

.fireworks {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allow clicks to pass through */
    z-index: 10; /* Ensure it appears above other content */
}

.firework {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 0, 0.8);
    border-radius: 50%;
    animation: explode 1s forwards;
}

@keyframes explode {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(5);
        opacity: 0;
    }
}

.bouncing-balls-container {
    position: absolute; /* Position absolutely to move behind the text */
    bottom: 0; /* Align at the bottom of the screen vertically */
    left: 50%; /* Align at the middle of the screen horizontally */
    width: 100%; /* Full width to cover the screen */
    height: 100px; /* Adjust height as needed */
    z-index: 1; /* Ensure it's behind the text */
    transform: translateX(-50%); /* Adjust to center horizontally */
}

.ball {
    position: absolute; /* Position absolutely within the container */
    width: 30px; /* Width of the ball */
    height: 30px; /* Height of the ball */
    border-radius: 50%; /* Make it circular */
    animation: bounce 2s ease-in-out infinite; /* Animation for bouncing */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust to center */
    z-index: 1; /* Add z-index for layering */
}

/* Define different colors for the balls */
.ball-1 { background-color: #20221B; }
.ball-2 { background-color: #F5ABBB; }
.ball-3 { background-color: #cfee9e; }
.ball-4 { background-color: #F6FCEE; }
.ball-5 { background-color: #F5ABBB; }
.ball-6 { background-color: #20221B; }
.ball-7 { background-color: #F5ABBB; }
.ball-8 { background-color: #cfee9e; }
.ball-9 { background-color: #F6FCEE; }
.ball-10 { background-color: #F5ABBB; }
.ball-11 { background-color: #20221B; }
.ball-12 { background-color: #F5ABBB; }
.ball-13 { background-color: #cfee9e; }
.ball-14 { background-color: #F6FCEE; }
.ball-15 { background-color: #F5ABBB; }
.ball-16 { background-color: #20221B; }
.ball-17 { background-color: #F5ABBB; }
.ball-18 { background-color: #cfee9e; }
.ball-19 { background-color: #F6FCEE; }
.ball-20 { background-color: #F5ABBB; }

/* Keyframes for bouncing animation */
@keyframes bounce {
    0%, 100% { transform: translateY(0); } /* Start and end at original position */
    50% { transform: translateY(-700px); } /* Move up by 100px */
}

/* Positioning the balls at random heights */
.ball-1 { left: 10%; animation-delay: 0s; }
.ball-2 { left: 20%; animation-delay: 0.3s; }
.ball-3 { left: 30%; animation-delay: 0.8s; }
.ball-4 { left: 40%; animation-delay: 1s; }
.ball-5 { left: 50%; animation-delay: 0.4s; }
.ball-6 { left: 60%; animation-delay: 0.5s; }
.ball-7 { left: 70%; animation-delay: 0.6s; }
.ball-8 { left: 80%; animation-delay: 1.7s; }
.ball-9 { left: 90%; animation-delay: 0.8s; }
.ball-10 { left: 10%; animation-delay: 1.9s; }
.ball-11 { left: 20%; animation-delay: 1s; }
.ball-12 { left: 30%; animation-delay: 0.1s; }
.ball-13 { left: 40%; animation-delay: 1.1s; }
.ball-14 { left: 50%; animation-delay: 1.3s; }
.ball-15 { left: 60%; animation-delay: 1.4s; }
.ball-16 { left: 70%; animation-delay: 0.5s; }
.ball-17 { left: 80%; animation-delay: 1.6s; }
.ball-18 { left: 90%; animation-delay: 0.7s; }
.ball-19 { left: 10%; animation-delay: 1.8s; }
.ball-20 { left: 20%; animation-delay: 0.9s; }

/* src/ImageStyles.css */
.navbar {
    padding: 10px;
    display: flex;
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
    background-color: rgba(32, 33, 27, 0.1); /* Navbar background color set to very transparent Eerie Black */
    color: #f6fcee; /* Text color set to Ivory */
}

.answers-label {
    font-size: 1.5rem;
    color: #ffffff; /* Ensure this contrasts with the background */
    text-align: center;
    margin-top: 100px;
    margin-bottom: 10px;
    font-weight: bold;
}

/* FrontPage.css */

.front-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    background-color: rgba(207, 238, 158, 0.5); /* Light background color, slightly transparent */
    font-family: Arial, sans-serif; /* Font style */
    text-align: center;
    z-index: 10; /* Added z-index style */
}

.front-page h1 {
    font-size: 2.0rem;
    margin-bottom: 1rem;
    color: #20221B; /* Changed to a soothing blue */
    text-shadow: 0 0 10px #F6FCEE, 0 0 20px #394830, 0 0 30px #F5ABBB; /* Flickering light effect */
    animation: flicker 1.5s infinite alternate; 
}

.front-page p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #20221B; /* Changed to a vibrant red */
    text-shadow: 0 0 10px #F6FCEE, 0 0 20px #394830, 0 0 30px #F5ABBB; /* Flickering light effect */
    animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ff2d95;
    }
    20%, 24%, 55% {        
        text-shadow: none;
    }
}

.start-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #20221B;
    background-color: #F5ABBB; /* Bootstrap primary color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.start-button:hover {
    background-color: #394830; /* Darker shade on hover */
}

/* Example CSS for the share button */
.share-button {
    background-color: #CFEE9E; /* Change background color */
    color: #394032; /* Change text color */
    padding: 10px 20px; /* Adjust padding */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition */
}

.share-button:hover {
    background-color: #F5ABBB; /* Darker shade on hover */
}